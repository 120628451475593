import { ReactElement } from 'react';
import {
  Modal as TonicModal,
  ModalCloseButton as TonicModalCloseButton,
  ModalOverlay as TonicModalOverlay,
  ModalContent as TonicModalContent,
  ModalHeader as TonicModalHeader,
  ModalBody as TonicModalBody,
  ModalFooter as TonicModalFooter,
  ModalProps as TonicModalProps,
} from '@tonic-ui/react';

type ModalProps = Omit<TonicModalProps, 'children'> &
  Required<Pick<TonicModalProps, 'isOpen' | 'onClose'>> & {
    header?: ReactElement;
    body: ReactElement;
    footer?: ReactElement;
  };

const Modal = (props: ModalProps) => {
  const {
    initialFocusRef,
    size,
    isOpen = false,
    onClose,
    scrollBehavior,
    header,
    body,
    footer,
    minHeight,
  } = props;

  return (
    <TonicModal
      autoFocus={true}
      closeOnEsc={true}
      closeOnOutsideClick={true}
      ensureFocus={true}
      initialFocusRef={initialFocusRef} // Should be the name input element
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior={scrollBehavior}
      size={size}
    >
      <TonicModalOverlay />
      <TonicModalContent minHeight={minHeight}>
        {header && <TonicModalHeader>{header}</TonicModalHeader>}
        <TonicModalBody>{body}</TonicModalBody>
        {footer && <TonicModalFooter>{footer}</TonicModalFooter>}
        <TonicModalCloseButton />
      </TonicModalContent>
    </TonicModal>
  );
};

Modal.displayName = 'Modal';
export default Modal;
