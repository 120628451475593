import { useContext, useState } from 'react';
import { Button, Box, Flex, Text, Link, Alert, useTheme } from '@tonic-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import CloudAccountSelectionControl from '../components/CloudAccountSelectionControl';
import SplitView from '../components/SplitView';
import CloudAssetsGraphContainer from '../components/CloudAssetsGraph/CloudAssetsGraphContainer';
import CloudAssetsContainer from '../components/CloudAssets/CloudAssetsContainer';
import ErrorBoundary from '../components/error/ErrorBoundary';
import AccountsAPIErrorOverlay from '../components/error/AccountsAPIErrorOverlay';
import GraphApiErrorOverlay from '../components/error/GraphApiErrorOverlay';
import { graphBGColorCode } from '../components/CloudAssetsGraph/helpers';
import { ReactComponent as CollapseIcon } from '../assets/Icons/collapse.svg';
import { ReactComponent as ExpandIcon } from '../assets/Icons/expand.svg';
import { getPendoGuide, PendoGuides, isPendoReady } from '../utils/pendo';
import Switch from '../components/Switch';
import { AppContext } from '../store/store';
import GAReleaseModal from '../components/GAReleaseModal';
import { Flags } from '../utils/feature-flags';

const V1_INT_STAG_DOMAINS = [
  'portal-xdr.visionone.trendmicro.com',
  'portal-int.visionone.trendmicro.com',
];

export interface CloudAccountInfo {
  accountID: string | undefined;
  accountRegion: string | undefined;
  accountAlias: string | undefined;
  accountProvider: SvcRisksApi.Parameters.Provider | undefined;
}

const RiskExplorer = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [{ selectedAccount, isGraphFullscreen, isRiskFocusViewActive }, dispatchGlobalStoreEvent] =
    useContext(AppContext);
  const { colors } = theme;
  const [showPreGAReleaseModal, setShowPreGAReleaseModal] = useState<boolean>(false);
  const [focusViewSwitchIsDisabled, setFocusViewSwitchIsDisabled] = useState<boolean>(true);
  const preReleaseDisclaimerLink = t('preReleaseDisclaimer.docLink');
  const platformDirectoryLink = '/index.html#/admin/sitemap';

  return (
    <ErrorBoundary ErrorComponent={AccountsAPIErrorOverlay}>
      <Flex
        data-track="REX_splitView_fullscreenState_default"
        direction="column"
        height="100%"
        overflow="hidden"
        {...(isGraphFullscreen && {
          'data-track': 'REX_splitView_fullscreenState_expanded',
          position: 'fixed',
          inset: '0',
          background: graphBGColorCode,
        })}
      >
        {!isGraphFullscreen && (
          <Flex gap="4x" alignItems="center" marginBottom="3x">
            <Alert
              flex="1"
              alignItems="center"
              variant="outline"
              severity="info"
              data-id="pre-release-alert"
            >
              {Flags.showGAReleaseBanner ? (
                <Flex gap="4x" alignItems="center" justifyContent="space-between">
                  <Text size="sm" color="white:emphasis">
                    <Trans
                      i18nKey="preGAReleaseDisclaimer.message"
                      components={{
                        platformDirectoryLink: (
                          <Link href={platformDirectoryLink} target="_blank" />
                        ),
                      }}
                    />
                  </Text>
                  <Button variant="secondary" onClick={() => setShowPreGAReleaseModal(true)}>
                    <Trans i18nKey="preGAReleaseDisclaimer.viewDetailsButtonText" />
                  </Button>
                </Flex>
              ) : (
                <Text size="sm" color="white:emphasis">
                  <Trans
                    i18nKey="preReleaseDisclaimer.message"
                    components={{
                      docLink: <Link href={preReleaseDisclaimerLink} target="_blank" />,
                    }}
                  />
                </Text>
              )}
            </Alert>
            {V1_INT_STAG_DOMAINS.includes(window.location.hostname) ||
            (isPendoReady() && getPendoGuide(PendoGuides.FeedbackForm)) ? (
              <Box>
                <Button variant="secondary" data-track="REX_openShareFeedback">
                  <Trans i18nKey="shareFeedback" />
                </Button>
              </Box>
            ) : null}
          </Flex>
        )}
        {Flags.showGAReleaseBanner && (
          <GAReleaseModal
            isOpen={showPreGAReleaseModal}
            closeModal={() => setShowPreGAReleaseModal(false)}
            platformDirectoryLink={platformDirectoryLink}
          />
        )}
        <SplitView
          top={<CloudAssetsContainer />}
          bottom={
            <Box position="relative" height="100%">
              <CloudAccountSelectionControl />
              <Flex
                gap="3x"
                position="absolute"
                background="gray:100"
                top="4x"
                right="1px"
                {...(isGraphFullscreen && { right: '4x' })}
              >
                <Switch
                  id="cloud-assets-graph-focused-view-toggle"
                  data-track="REX_focusedView_toggle"
                  data-id="REX_focusedView_toggle"
                  label={t('focusedView')}
                  checked={isRiskFocusViewActive}
                  onChange={(e) => {
                    dispatchGlobalStoreEvent({
                      type: 'SET_RISK_FOCUSED_VIEW',
                      payload: e.target.checked,
                    });
                    setFocusViewSwitchIsDisabled(true);
                  }}
                  disabled={focusViewSwitchIsDisabled}
                />
                <Button
                  data-id="cloud-assets-graph-expand-button"
                  data-track="REX_graphButton_expand"
                  onClick={() => {
                    dispatchGlobalStoreEvent({
                      type: 'SET_FULLSCREEN',
                      payload: !isGraphFullscreen,
                    });
                  }}
                  variant="secondary"
                  backgroundColor={graphBGColorCode}
                  paddingLeft="2x"
                  paddingRight="2x"
                  {...(isGraphFullscreen && {
                    'data-track': 'REX_graphButton_collapse',
                    backgroundColor: 'blue:60',
                    variant: 'primary',
                  })}
                >
                  {isGraphFullscreen ? (
                    <CollapseIcon fill={colors['white:emphasis']} width="14px" height="14px" />
                  ) : (
                    <ExpandIcon fill="currentColor" width="14px" height="14px" />
                  )}
                  <Text paddingLeft="2x">
                    <Trans i18nKey={isGraphFullscreen ? 'collapse' : 'expand'} />
                  </Text>
                </Button>
              </Flex>
              <ErrorBoundary
                key={JSON.stringify(selectedAccount)}
                ErrorComponent={GraphApiErrorOverlay}
              >
                <CloudAssetsGraphContainer
                  activeGraphType={isRiskFocusViewActive ? 'riskView' : 'networkView'}
                  setRiskViewSwitchIsDisabled={setFocusViewSwitchIsDisabled}
                />
              </ErrorBoundary>
            </Box>
          }
          isFullScreen={isGraphFullscreen}
        />
      </Flex>
    </ErrorBoundary>
  );
};

export default RiskExplorer;
