import { Text, Flex, Link, Button } from '@tonic-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from './modal/modal';

const GAReleaseModalContent = (props: { platformDirectoryLink: string }) => {
  const { platformDirectoryLink } = props;
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap="4x">
      <Text>
        <Trans
          i18nKey="preGAReleaseModal.mainText"
          components={{
            preReleaseDisclaimerLink: (
              <Link href={t('preGAReleaseModal.preReleaseDisclaimerLink')} target="_blank" />
            ),
          }}
        />
      </Text>
      <Flex flexDirection="column" gap="6x" paddingY="4x">
        <Flex>
          <Text color="white:secondary" width="180px">
            <Trans i18nKey="preGAReleaseModal.featureLabel" />
          </Text>
          <Flex flex="1">
            <Trans i18nKey="preGAReleaseModal.featureValue" />
          </Flex>
        </Flex>
        <Flex>
          <Text color="white:secondary" width="180px">
            <Trans i18nKey="preGAReleaseModal.descriptionLabel" />
          </Text>
          <Flex flex="1">
            <Trans i18nKey="preGAReleaseModal.descriptionValue" />
          </Flex>
        </Flex>
        <Flex>
          <Text color="white:secondary" width="180px">
            <Trans i18nKey="preGAReleaseModal.officialReleaseLabel" />
          </Text>
          <Flex flex="1">
            <Trans i18nKey="preGAReleaseModal.officialReleaseValue" />
          </Flex>
        </Flex>
      </Flex>
      <ul
        style={{
          paddingLeft: '20px',
          marginTop: 0,
        }}
      >
        <li
          style={{
            marginBottom: '5px',
          }}
        >
          <Trans i18nKey="preGAReleaseModal.detailsListItemOne" />
        </li>
        <li>
          <Trans
            i18nKey="preGAReleaseModal.detailsListItemTwo"
            components={{
              platformDirectoryLink: <Link target="_blank" href={platformDirectoryLink} />,
            }}
          />
        </li>
      </ul>
    </Flex>
  );
};

const GAReleaseModal = (props: {
  isOpen: boolean;
  closeModal: () => void;
  platformDirectoryLink: string;
}) => {
  const { isOpen, closeModal, platformDirectoryLink } = props;
  return (
    <Modal
      size="md"
      header={<Trans i18nKey="preGAReleaseModal.title" />}
      body={<GAReleaseModalContent platformDirectoryLink={platformDirectoryLink} />}
      footer={
        <Button onClick={() => closeModal()}>
          <Trans i18nKey="close" />
        </Button>
      }
      isOpen={isOpen}
      onClose={closeModal}
    />
  );
};

GAReleaseModal.displayName = 'GAReleaseModal';
export default GAReleaseModal;
